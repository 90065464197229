"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.LogoutPage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class LogoutPage extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({ mode: 'open' });
        this.serverProperties = (0, lib_es2015_2.getServerProps)();
        this.apis = new apis_1.default();
        this.redirectUrl = '';
        this.getUserName = (isFullName) => {
            var _a, _b;
            if (!this.isLogin())
                return 'Guest';
            const member = this.serverProperties.member;
            if (member === null) {
                // Since isLogin() is true, this.serverProperties.globalIdentifier.identifier exist!
                return `${this.serverProperties.globalIdentifier.identifier}`;
            }
            if (!isFullName)
                return member.nick;
            return `${(_a = member.pre_title) !== null && _a !== void 0 ? _a : ''}${member.nick}${(_b = member.post_title) !== null && _b !== void 0 ? _b : ''}`;
        };
        this.isLogin = () => {
            const globalIdentifier = this.serverProperties.globalIdentifier;
            if (globalIdentifier === undefined || globalIdentifier === null)
                return false;
            const identifier = globalIdentifier.identifier;
            if (identifier === undefined || identifier === null || identifier === '')
                return false;
            return true;
        };
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            const apiResp = yield this.apis.logout();
            if (apiResp === null)
                return;
            if (this.redirectUrl === '')
                return;
            location.href = this.redirectUrl;
        });
        console.debug('LoginPage constructed!');
    }
    connectedCallback() {
        var _a;
        this.redirectUrl = (_a = this.getAttribute('redirect-url')) !== null && _a !== void 0 ? _a : '';
        if (this.isLogin()) {
            this.shadow.innerHTML = `
        <style>
          div.main {
            width: fit-content;
            height: 80vh;
            margin: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
          }
        </style>
        <div class="main">
          Good Bye ${this.getUserName(true)}!<br>
          You are logging out and will be redirected to ${this.redirectUrl}.<br>
          If you are not, please, try to log out manually.<br> 
          <button class="logout">Logout Manullay</button>
        </div>`;
            const logoutBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'button.logout');
            logoutBtn.addEventListener('click', () => {
                void this.logout();
            });
        }
        else {
            this.shadow.innerHTML = `
        <style>
          div.main {
            width: fit-content;
            height: 80vh;
            margin: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
          }
        </style>
        <div class="main">
          You are already logout.
        </div>`;
        }
        void this.logout();
    }
}
exports.LogoutPage = LogoutPage;
const define = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, LogoutPage);
};
exports.define = define;
