"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.LoginPage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class LoginPage extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({ mode: 'open' });
        this.serverProperties = (0, lib_es2015_2.getServerProps)();
        this.apis = new apis_1.default();
        this.redirectUrl = '';
        this.setupDirectLoginForm = () => {
            const directLoginEmailInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'input.email');
            const directLoginPasswordInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'input.password');
            const directLoginBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.directLoginForm, 'button.login-btn');
            directLoginEmailInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter')
                    void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
            directLoginPasswordInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter')
                    void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
            directLoginBtn.addEventListener('click', () => {
                void this.login(directLoginEmailInput.value, directLoginPasswordInput.value);
            });
        };
        this.setupOauthLoginForm = () => __awaiter(this, void 0, void 0, function* () {
            const btnContainer = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.oauthLoginForm, 'div.oauth-btn-container');
            const oauthContexts = yield this.apis.getOauthContexts();
            for (const resourceServer in oauthContexts) {
                const oauthContext = oauthContexts[resourceServer];
                btnContainer.appendChild((0, lib_es2015_3.buildNodeByOuterHTML)(`<a href="${oauthContext}"><button>${resourceServer}</button></a>`));
            }
        });
        this.login = (id, password) => __awaiter(this, void 0, void 0, function* () {
            if (id === '') {
                alert(`put email`);
                return;
            }
            if (password === '') {
                alert(`put password`);
                return;
            }
            const apiResp = yield this.apis.login({ id, password });
            if (apiResp === null)
                return;
            if (this.redirectUrl === '')
                return;
            location.href = this.redirectUrl;
        });
        this.getUserName = (isFullName) => {
            var _a, _b;
            if (!this.isLogin())
                return 'Guest';
            const member = this.serverProperties.member;
            if (member === null) {
                // Since isLogin() is true, this.serverProperties.globalIdentifier.identifier exist!
                return `${this.serverProperties.globalIdentifier.identifier}`;
            }
            if (!isFullName)
                return member.nick;
            return `${(_a = member.pre_title) !== null && _a !== void 0 ? _a : ''}${member.nick}${(_b = member.post_title) !== null && _b !== void 0 ? _b : ''}`;
        };
        this.isLogin = () => {
            const globalIdentifier = this.serverProperties.globalIdentifier;
            if (globalIdentifier === undefined || globalIdentifier === null)
                return false;
            const identifier = globalIdentifier.identifier;
            if (identifier === undefined || identifier === null || identifier === '')
                return false;
            return true;
        };
        console.debug('LoginPage constructed!');
    }
    connectedCallback() {
        var _a, _b;
        this.redirectUrl = (_a = this.getAttribute('redirect-url')) !== null && _a !== void 0 ? _a : '';
        if (this.isLogin()) {
            this.shadow.innerHTML = `
        <style>
          div.main {
            width: fit-content;
            height: 80vh;
            margin: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
          }
          div.user-control {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            margin-top: 0.5rem;
          }
        </style>
        <div class="main">
          <div class="login-message">You have already logged in</div>
          <div class="user-control">
            <div class="user-name">${this.getUserName(true)}</div>
            <button class="edit-user">User Info</button>
            <button class="logout">Logout</button>
          </div>
        </div>`;
            const editUserBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'button.edit-user');
            editUserBtn.addEventListener('click', () => {
                window.location.href = `/web/edit?redirect_url=${this.redirectUrl}`;
            });
            const logoutBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'button.logout');
            logoutBtn.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.logout();
                    if (apiResp === null)
                        return;
                    if (this.redirectUrl === '')
                        return;
                    location.href = this.redirectUrl;
                }))();
            });
        }
        else {
            const urlParams = new URLSearchParams(window.location.search);
            const loginType = (_b = urlParams.get('login_type')) !== null && _b !== void 0 ? _b : 'both';
            this.shadow.innerHTML = `
        <style>
          div.main {
            width: fit-content;
            height: 80vh;
            margin: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
          }
          div.login-form.direct-login {
            display: flex;
            gap: 0.2rem;
            margin: 1em 0;
          }
          div.login-form.oauth-login {
            margin: 1em 0;
          }
          div.oauth-btn-container {
            display: flex;
            gap: 0.2rem;
            flex-wrap: wrap;
            margin-top: 0.5em;
          }
          hr {
            width: 100%;
            height: 1px;
          }
        </style>
        <div class="main">
          <div class="login-form direct-login">
            <div>
              <input class="email" type="email" placeholder="E-mail"/>
            </div>
            <div>
              <input class="password" type="password" placeholder="Password"/>
            </div>
            <button class="login-btn">Login</button>
          </div>
          <hr class="direct-login oauth-login" />
          <div class="login-form oauth-login">
            <div>OAuth Logins</div>
            <div class="oauth-btn-container"></div>
          </div>
        </div>`;
            this.directLoginForm = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'div.login-form.direct-login');
            this.oauthLoginForm = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'div.login-form.oauth-login');
            const hr = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'hr');
            switch (loginType) {
                case 'direct':
                    this.setupDirectLoginForm();
                    this.oauthLoginForm.style.setProperty('display', 'none');
                    hr.style.setProperty('display', 'none');
                    break;
                case 'oauth':
                    void this.setupOauthLoginForm();
                    this.directLoginForm.style.setProperty('display', 'none');
                    hr.style.setProperty('display', 'none');
                    break;
                case 'both':
                    this.setupDirectLoginForm();
                    void this.setupOauthLoginForm();
                    break;
                default:
                    throw Error(`Unsupported login type: ${loginType}`);
            }
        }
    }
}
exports.LoginPage = LoginPage;
const define = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, LoginPage);
};
exports.define = define;
