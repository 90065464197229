"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.define = exports.EditUser = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_4 = require("@hodol/complex-inputs/lib-es2015");
class EditUser extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({ mode: 'open' });
        this.serverProperties = (0, lib_es2015_2.getServerProps)();
        this.apis = new apis_1.default();
        this.redirectUrl = '';
        this.getUserName = (isFullName) => {
            var _a, _b;
            if (!this.isLogin())
                return 'Guest';
            const member = this.serverProperties.member;
            if (member === null) {
                // Since isLogin() is true, this.serverProperties.globalIdentifier.identifier exist!
                return `${this.serverProperties.globalIdentifier.identifier}`;
            }
            if (!isFullName)
                return member.nick;
            return `${(_a = member.pre_title) !== null && _a !== void 0 ? _a : ''}${member.nick}${(_b = member.post_title) !== null && _b !== void 0 ? _b : ''}`;
        };
        this.isLogin = () => {
            const globalIdentifier = this.serverProperties.globalIdentifier;
            if (globalIdentifier === undefined || globalIdentifier === null)
                return false;
            const identifier = globalIdentifier.identifier;
            if (identifier === undefined || identifier === null || identifier === '')
                return false;
            return true;
        };
        console.debug('LoginPage constructed!');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.serverProperties.email === '') {
                alert(`You need to login`);
                return;
            }
            if (this.serverProperties.member === null) {
                alert(`Member information is null, You may not use OAuth login.`);
                window.history.back();
                return;
            }
            this.redirectUrl = (_a = this.getAttribute('redirect-url')) !== null && _a !== void 0 ? _a : '';
            const member = this.serverProperties.member;
            if (member.nick === undefined || member.nick === null)
                member.nick = '';
            if (member.pre_title === undefined || member.pre_title === null)
                member.pre_title = '';
            if (member.post_title === undefined || member.post_title === null)
                member.post_title = '';
            if (!member.is_initialized) {
                const joinedMember = yield this.apis.getJoinedMemberByKey({ _key: member._key });
                if (joinedMember === null) {
                    alert('fail to get Member info.');
                    return;
                }
                member.extra = Object.fromEntries(joinedMember.oauth_client.member_extra_keys.map(key => [key, '']));
            }
            const globalIdentifier = this.serverProperties.globalIdentifier;
            this.shadow.innerHTML = `
      <style>
        div.main {
          width: fit-content;
          height: 80vh;
          margin: auto;
          display: grid;
          grid-template-columns: auto auto;
          align-content: center;
          grid-gap: 0.2rem;
        }
        div.edit-form-field-name {
          text-align: right;
          font-weight: bold;
          padding-right: 0.2rem;
        }
        input.edit-form-input {
            width: 100%;
        }
        div.edit-form-btn-container {
          text-align: right;
        }
      </style>
      <div class="main">
        <div class="edit-form-field-name">you are</div>
        <div>
            ${globalIdentifier.display_name}<br>
            (${globalIdentifier.identifier} in ${globalIdentifier.resource_server})
        </div>
        <div class="edit-form-field-name">_key</div>
        <div>${member._key}</div>
        <div class="edit-form-field-name">Nick</div>
        <div><input class="edit-form-input nick" /></div>
        <div class="edit-form-field-name">Pre-title</div>
        <div><input class="edit-form-input pre-title" /></div>
        <div class="edit-form-field-name">Post-title</div>
        <div><input class="edit-form-input post-title" /></div>
        <div class="edit-form-field-name">Extra</div>
        <div><input-map class="extra" max-key-textarea-width="200" max-value-textarea-width="500"></input-map></div>
        <div class="edit-form-field-name"></div>
        <div class="edit-form-btn-container">
          <button class="submit-btn">Submit</button>
          <button class="cancel-btn">Cancel</button>
        </div>
      </div>`;
            const nickInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'input.nick');
            nickInput.value = member.nick;
            nickInput.addEventListener('change', () => {
                member.nick = nickInput.value;
            });
            const preTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'input.pre-title');
            preTitleInput.value = member.pre_title;
            preTitleInput.addEventListener('change', () => {
                member.pre_title = preTitleInput.value;
            });
            const postTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'input.post-title');
            postTitleInput.value = member.post_title;
            postTitleInput.addEventListener('change', () => {
                member.post_title = postTitleInput.value;
            });
            const extraInputMap = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'input-map.extra');
            extraInputMap.value = member.extra;
            extraInputMap.addEventListener('change', () => {
                member.extra = extraInputMap.value;
            });
            const submitBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'button.submit-btn');
            submitBtn.addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    if (member.nick === '') {
                        alert(`Please, put your nick name!`);
                        nickInput.focus();
                        return;
                    }
                    member.is_initialized = true;
                    const result = yield this.apis.update(member);
                    if (result !== null) {
                        alert(`Update successfully! Please, login again!`);
                        const resp = yield this.apis.logout();
                        if (resp === null)
                            return;
                        if (this.redirectUrl === '')
                            location.href = '/web/login';
                        location.href = this.redirectUrl;
                    }
                }))();
            });
            const cancelBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this.shadow, 'button.cancel-btn');
            cancelBtn.addEventListener('click', () => window.history.back());
        });
    }
}
exports.EditUser = EditUser;
const define = (name) => {
    (0, lib_es2015_4.defineInputMap)('input-map');
    (0, lib_es2015_1.defineIfUndefined)(name, EditUser);
};
exports.define = define;
